import { PageProps } from 'gatsby';
import React from 'react';

const NotFoundPage: React.FunctionComponent<PageProps> = () => {
  return (
    <>
      <h1>
        404 Not Found
      </h1>
    </>
  );
};

export default NotFoundPage;
